export const photos = [
  {
    src: "images/zeemp1.png",
    width: 3,
    height: 1
  },
  {
    src: "images/zeemp2.png",
    width: 3,
    height: 1
  }
];
