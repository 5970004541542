import React from "react";
import PortfolioA from "./../PortfolioA";
import Portfolio from "./../Portfolio";
import Portfolio1 from "./../Portfolio1";
import Portfolio2 from "./../Portfolio2";
import Portfolio5 from "./../Portfolio5";
import Portfolio6 from "./../Portfolio6";
import Portfolio8 from "./../Portfolio8";
import PortfolioABC from "./../PortfolioABC";
import "./styles.scss";
import "react-vertical-timeline-component/style.min.css";

function Experience() {
  return (
    <section id="Experiences" className="ExperiencesSection section">
      <div className="container">
        <h1 className="title">Experience</h1>

        <div className="vertical-timeline-element-content">
          <h3 className="vertical-timeline-element-title">
            UX/UI Engineer
          </h3>
          <h4 className="vertical-timeline-element-subtitle">Trevon</h4>
          <p>App for importing brokerage notes from investment brokers and generating information for payment of taxes. In addition to enabling the users to manage their investment portfolio, real estate funds and shares. </p>
          <p>
            <span className="button is-rounded is-small is-static">UI/UX DESIGN</span>{" "}
            <span className="button is-rounded is-small is-static">HTML/CSS</span>{" "}
            <span className="button is-rounded is-small is-static">JS</span>{" "}
            <span className="button is-rounded is-small is-static">IONIC + REACT</span>
          </p>
          <br />
          <Portfolio2 />
        </div>

        <div className="vertical-timeline-element-content">
          <h3 className="vertical-timeline-element-title">
            UX/UI Engineer
          </h3>
          <h4 className="vertical-timeline-element-subtitle">CPQi</h4>
          <p>
            Development of solutions for Onboarding of clients for digital banking platforms
          </p>
          <p>
            <span className="button is-rounded is-small is-static">UI/UX DESIGN</span>{" "}
            <span className="button is-rounded is-small is-static">HTML/CSS</span>{" "}
            <span className="button is-rounded is-small is-static">JS</span>{" "}
            <span className="button is-rounded is-small is-static">REACT</span>
          </p>
          <br />
          <PortfolioABC />
        </div>

        <div className="vertical-timeline-element-content">
          <h3 className="vertical-timeline-element-title">
            UX/UI Engineer
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            BASA - Banco da Amazônia
          </h4>
          <p>
            Application development for credit advisors
            of the Bank of the Amazon. The solution is aimed at optimization
            the process of granting resources to small entrepreneurs.
          </p>
          <p>
            <span className="button is-rounded is-small is-static">UX/UI DESIGN</span>{" "}
            <span className="button is-rounded is-small is-static">HTML/CSS</span>{" "}
            <span className="button is-rounded is-small is-static">JS</span>{" "}
            <span className="button is-rounded is-small is-static">
              IONIC
            </span>{" "}
            <span className="button is-rounded is-small is-static">
              {" "}
              ANGULAR
            </span>
          </p>
          <br />
          <PortfolioA />
        </div>

        <div className="vertical-timeline-element-content">
          <h3 className="vertical-timeline-element-title">
            UX/UI Engineer
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            BB Tecnologia e Serviços
          </h4>
          <p>
            The company's digital solutions offer reliable technologies to enhance the digital transformation of organizations in Brazil. Design and front-end development of a dozen systems were carried out, one of which is PSIM (Physical Security Information Manager). This system integrates the monitoring of several electronic channels. It reduces “false alarms” and also allows correlating alerts and handling incidents with standard and agility.
          </p>
          <p>
            <span className="button is-rounded is-small is-static">UX/UI DESIGN</span>{" "}
            <span className="button is-rounded is-small is-static">HTML/CSS</span>{" "}
            <span className="button is-rounded is-small is-static">JS</span>{" "}
            <span className="button is-rounded is-small is-static">ANGULAR</span>{" "}
            <span className="button is-rounded is-small is-static">REACT</span>
          </p>
          <br />
          <Portfolio />
        </div>

        <div className="vertical-timeline-element-content">
          <h3 className="vertical-timeline-element-title">
            UX/UI Engineer
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Desenvolve Cidade
          </h4>
          <p>
            The company Desenvolvimento Cidade offers solutions in the field of Nota
            Electronic Tax and debureaucratization of the opening and company licensing. With the Digital Enterprise platform,
            participated in the design of the application and remodeling of the landing
            project page.
          </p>
          <p>
            <span className="button is-rounded is-small is-static">UX/UI DESIGN</span>{" "}
            <span className="button is-rounded is-small is-static">HTML/CSS</span>{" "}
            <span className="button is-rounded is-small is-static">JS</span>{" "}
            <span className="button is-rounded is-small is-static">ANGULAR</span>{" "}
            <span className="button is-rounded is-small is-static">REACT</span>
          </p>
          <br />
          <Portfolio1 />
        </div>

        <div className="vertical-timeline-element-content">
          <h3 className="vertical-timeline-element-title">
            UX/UI Engineer
          </h3>
          <h4 className="vertical-timeline-element-subtitle">Freelancer</h4>
          <p>
            Acting as an independent professional in a series of projects.
          </p>
          <p>
            <span className="button is-rounded is-small is-static">HTML/CSS</span>{" "}
            <span className="button is-rounded is-small is-static">
              WORDPRESS
            </span>{" "}
            <span className="button is-rounded is-small is-static">
              DRUPAL
            </span>
          </p>
          <br />
          <Portfolio5 />
        </div>

        <div className="vertical-timeline-element-content">
          <h3 className="vertical-timeline-element-title">
            UX/UI Engineer
          </h3>
          <h4 className="vertical-timeline-element-subtitle">Zeemp</h4>
          <p>
            Zeemp was a social business directory. Reach more than 1
            thousand users per month. The users could endorse you
            best local services and find like-minded people.
          </p>
          <p>
            <span className="button is-rounded is-small is-static">HTML/CSS</span>{" "}
            <span className="button is-rounded is-small is-static">DRUPAL</span>{" "}
          </p>
          <br />
          <Portfolio6 />
        </div>

        <div className="vertical-timeline-element-content">
          <h3 className="vertical-timeline-element-title">
            UX/UI Engineer
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Universidade Federal do Pará - PROGEP
          </h4>
          <p>
            PROGEP is responsible for the implementation of Personnel Management development policies at UFPA. Therefore, it is responsible for dealing with both the selection of civil servants via public tender, financial matters and movements practiced during their working life, as well as promoting career development opportunities and actions for promotion, prevention and health care, among others. initiatives that aim to provide a better quality of working life for UFPA employees.
          </p>
          <p>
            <span className="button is-rounded is-small is-static">HTML/CSS</span>{" "}
            <span className="button is-rounded is-small is-static">ADOBE</span>{" "}
          </p>
          <br />
          <Portfolio8 />
        </div>
      </div>
    </section >
  );
}

export default Experience;