export const photos = [
  {
    src: "images/abc1.png",
    width: 3,
    height: 4
  },
  {
    src: "images/abc2.png",
    width: 3,
    height: 4
  },
  {
    src: "images/abc3.png",
    width: 3,
    height: 4
  },
  {
    src: "images/abc4.png",
    width: 3,
    height: 4
  }
];
