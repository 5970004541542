export const photos = [
  {
    src: "images/a.png",
    width: 2,
    height: 4
  },
  {
    src: "images/b.png",
    width: 2,
    height: 4
  },
  {
    src: "images/c.png",
    width: 2,
    height: 4
  },
  {
    src: "images/d.png",
    width: 2,
    height: 4
  }
];
