export const photos = [
  {
    src: "images/bp1.jpg",
    width: 2,
    height: 4
  },
  {
    src: "images/bp2.jpg",
    width: 1,
    height: 4
  },
  {
    src: "images/bp3.png",
    width: 3,
    height: 4
  },
  {
    src: "images/bp4.png",
    width: 3,
    height: 4
  }
];
