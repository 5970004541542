export const photos = [
  {
    src: "images/empresadigital1.png",
    width: 5,
    height: 3
  },
  {
    src: "images/empresadigital2.png",
    width: 4,
    height: 3
  },
  {
    src: "images/empresadigital3.png",
    width: 3,
    height: 4
  },
  {
    src: "images/empresadigital4.png",
    width: 3,
    height: 4
  }
];
