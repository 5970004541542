export const photos = [
  {
    src: "images/alonsoaymore.png",
    width: 3,
    height: 1
  },
  {
    src: "images/alonsoaymore2.png",
    width: 2,
    height: 3
  },
  {
    src: "images/lpdna.png",
    width: 3,
    height: 1
  },
  {
    src: "images/criolipolise.jpg",
    width: 3,
    height: 3
  },
  {
    src: "images/ceva.png",
    width: 3,
    height: 1
  },
  {
    src: "images/fale.png",
    width: 3,
    height: 1
  },
  {
    src: "images/falem.png",
    width: 3,
    height: 1
  },
  {
    src: "images/ida.png",
    width: 3,
    height: 1
  },

  {
    src: "images/ritualacademia.jpg",
    width: 2,
    height: 3
  },
  {
    src: "images/ritualestetica1.jpg",
    width: 3,
    height: 3
  }
];
