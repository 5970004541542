export const photos = [
  {
    src: "images/1.png",
    width: 2,
    height: 3
  },
  {
    src: "images/2.png",
    width: 2,
    height: 3
  },
  {
    src: "images/3.png",
    width: 2,
    height: 3
  },
  {
    src: "images/4.png",
    width: 2,
    height: 3
  },
  {
    src: "images/5.png",
    width: 2,
    height: 3
  },
  {
    src: "images/6.png",
    width: 2,
    height: 3
  }
];
